import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChartNetwork } from "@fortawesome/pro-regular-svg-icons";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/card";
import OnlineConnectionSvg from "../images/undraw/undraw_online_connection_6778.svg";
import LogoCloud from "../components/LogoCloud";

const AudienceInteractionsPage = () => {
  return (
    <Layout>
      {(linkClicked, cardVisible) => (
        <>
          <SEO keywords={[]} title="Audience Interactions" />
          <Card
            visible={cardVisible}
            header="Instantaneous Audience Interactions via Websockets"
            previousUrl="/custom-cms"
            previousLabel="Custom Cms"
            nextUrl="/offline-capabilities"
            nextLabel="Offline Capabilities"
          >
            <p className="text-base leading-6 text-gray-700 text-left mb-4 sm:mb-5">
              Distributed applications often entail the need for real-time
              interactions taking place between participants. Using WebSockets,
              a persistent connection between (multiple) clients and servers can
              be established and leveraged for, e.g.
            </p>
            <ul className="text-base leading-6 text-gray-700 text-left mb-4 sm:mb-5 list-disc list-inside">
              <li>object-based interactions</li>
              <li>conversational interfaces, such as chat bots</li>
              <li>
                low-latency interactions, e.g. for artistic performances or
                collaborative editing
              </li>
            </ul>
            <div className="grid gap-6 grid-cols-1">
              <figure className="w-full">
                <img alt="content" src={OnlineConnectionSvg} />
              </figure>
            </div>
          </Card>
          <LogoCloud
            logos={[
              {
                name: "kreativfarm",
                url: "http://kreativ.farm/",
              },
              { name: "zone", url: "https://zonemedia.at/" },
            ]}
          />
        </>
      )}
    </Layout>
  );
};

export default AudienceInteractionsPage;
